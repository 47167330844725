<template>
  <section id="tickets" class="py-16 bg-gray-900 text-white">
    <div class="container mx-auto px-4">
      <h2 class="text-4xl font-bold mb-8 text-center">Waar te Zien</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-8">
        <div v-for="cinema in cinemas" :key="cinema.id" class="cinema-card p-6 bg-gray-800 rounded-lg shadow-lg">
          <img :src="cinema.logo" :alt="cinema.name" class="h-20 mx-auto mb-4" />
          <h3 class="text-2xl font-semibold mb-2 text-center">{{ cinema.name }}</h3>
          <p class="text-center">{{ cinema.location }}</p>
          <p class="text-center text-gray-400">{{ cinema.date }}</p>
          <a :href="cinema.link" target="_blank" class="btn koop-tickets-btn flex items-center justify-center gap-2">
            <span>KOOP TICKETS</span>
            <span class="iconify" data-icon="ion:ticket" aria-hidden="true"></span>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import patheLogo from '/src/assets/bioscoop_pathe_logo.png'
import kinepolisLogo from '/src/assets/bioscoop_kinepolis_logo.png'
import vuecinemaLogo from '/src/assets/bioscoop_vue_logo.png'
import vlugtLogo from '/src/assets/bioscoop_vlugt_logo.png'
import goudaLogo from '/src/assets/bioscoop_gouda_logo.png'
import cinecityLogo from '/src/assets/cinecity.jpg'
import foroxityLogo from '/src/assets/foroxity.jpeg'
import kokcinemaxxLogo from '/src/assets/bioscoop_kokcinemaxx_logo.png'
import biosdrachtenLogo from '/src/assets/bioscoop_drachten_logo.png'
import industryLogo from '/src/assets/bioscoop_industry_logo.png'
import citycinemaLogo from '/src/assets/bioscoop_citycinema_logo.svg'
import almelo from '/src/assets/bioscoop_movieunlimitedalmelo_logo.png'
import hengelo from '/src/assets/bioscoop_movieunlimitedhengelo_logo.png'
import kampen from '/src/assets/bioscoop_movieunlimitedkampen_logo.png'
import royal from '/src/assets/bioscoop_royalecht_logo.jpeg'
import cuijck from '/src/assets/bioscoop_industrycuijk_logo.png'
import leiden from '/src/assets/bioscoop_bioscopenleiden_logo.png'
import aroma from '/src/assets/bioscoop_cinemaroma_logo.svg'
import cinetwins from '/src/assets/bioscoop_cinetwins_logo.svg'
import cinemagold from '/src/assets/bioscoop_cinemagold_logo.svg'
import cinelounge from '/src/assets/bioscoop_cinelounge_logo.svg'
import taketen from '/src/assets/bioscoop_taketen_logo.svg'
import skopein from '/src/assets/bioscoop_skopein_logo.png'
import movieskoop from '/src/assets/bioscoop_movieskoop_logo.png'
import cinesneek from '/src/assets/bioscoop_cinesneek_logo.png'
import bioshardenberg from '/src/assets/bioscoop_bioshardenberg_logo.png'
import dnk from '/src/assets/bioscoop_dnk_logo.png'
import cineworld from '/src/assets/bioscoop_cineworld_logo.svg'
import luxor from '/src/assets/bioscoop_luxor_logo.png'
import hollywoud from '/src/assets/bioscoop_hollywoud_logo.png'

export default {
  data() {
    return {
      cinemas: [
        {
          id: 1,
          name: 'Pathé',
          logo: patheLogo,
          location: 'Meerdere locaties',
          link: 'https://www.pathe.nl/film/27226/loverboy-emoties-uit'
        },
        {
          id: 2,
          name: 'Kinepolis',
          logo: kinepolisLogo,
          location: 'Meerdere locaties',
          link: 'https://kinepolis.nl/movies/detail/34382/HO00005866/0/loverboy-emoties-uit'
        },
        {
          id: 3,
          name: 'Kinepolis BE',
          logo: kinepolisLogo,
          location: 'Antwerpen',
          link: 'https://kinepolis.be/nl/movies/detail/34382/HO00010947/0/loverboy-emoties-uit/'
        },
        {
          id: 4,
          name: 'Vue Cinema',
          logo: vuecinemaLogo,
          location: 'Meerdere locaties',
          link: 'https://www.vuecinemas.nl/films/film/loverboy-emoties-uit'
        },
        {
          id: 5,
          name: 'Cinema de Vlugt',
          logo: vlugtLogo,
          location: 'Amsterdam Vlugtlaan',
          link: 'https://www.cinemadevlugt.nl/film/loverboy-emoties-uit/'
        },
        {
          id: 6,
          name: 'Cinema Gouda',
          logo: goudaLogo,
          location: 'Gouda',
          link: 'https://www.cinemagouda.nl/film/loverboy-emoties-uit-16'
        },
        {
          id: 7,
          name: 'CineCity',
          logo: cinecityLogo,
          location: 'Vlissingen',
          link: 'https://www.cinecity.nl/movies/5857/17/loverboy_emoties_uit'
        },
        {
          id: 8,
          name: 'Foroxity',
          logo: foroxityLogo,
          location: 'Sittard & Roermond',
          link: 'https://foroxity.nl/Browsing/Movies/Details/h-HO00003344'
        },
        {
          id: 9,
          name: 'Kok Cinemaxx ',
          logo: kokcinemaxxLogo,
          location: 'Harderwijk & Lelystad',
          link: 'https://www.kokcinemaxx.nl/film/loverboy-emoties-uit/#/shows/107565'
        },
        {
          id: 10,
          name: 'Bios Drachten',
          logo: biosdrachtenLogo,
          location: 'Drachten',
          link: 'https://www.biosdrachten.nl/movies/2132/17/loverboy_emoties_uit_16'
        }
        ,
        {
          id: 11,
          name: 'Industry Bioscoop Veghel',
          logo: industryLogo,
          location: 'Veghel',
          link: 'https://veghel.industrybioscoop.nl/movies/1565/17/loverboy_emoties_uit'
        },
        {
          id: 12,
          name: 'City Cinema',
          logo: citycinemaLogo,
          location: 'Venlo',
          link: 'https://www.citycinema.nl/movies/1369/202/loverboy_emoties_uit'
        },
        {
          id: 13,
          name: 'Movie Unlimited',
          logo: almelo,
          location: 'Almelo',
          link: 'https://almelo.movieunlimitedbioscopen.nl/movies/1744/17/loverboy_emoties_uit'
        },
        {
          id: 14,
          name: 'Movie Unlimited',
          logo: hengelo,
          location: 'Hengelo',
          link: 'https://hengelo.movieunlimitedbioscopen.nl/movies/1744/17/loverboy_emoties_uit'
        },
        {
          id: 15,
          name: 'Royal Echt',
          logo: royal,
          location: 'Echt',
          link: 'https://www.royalecht.nl/movies/383/17/loverboy_emoties_uit'
        },
        {
          id: 16,
          name: 'Industry Bioscoop Cuijck',
          logo: cuijck,
          location: 'Cuijck',
          link: 'https://cuijk.industrybioscoop.nl/movies/1527/17/loverboy_emoties_uit'
        },
        {
        id: 16,
        name: 'Bioscopen Leiden',
        logo: leiden,
        location: 'Leiden',
        link: 'https://bioscopenleiden.nl/lido/?faf=today'
        },
        {
          id: 17,
          name: 'Cinema Roma',
          logo: aroma,
          location: 'Wijchen',
          link: 'https://www.cinemaroma.nl/filmprogramma/loverboy-emoties-uit-16'
        },
        {
          id: 19,
          name: 'Cine Twins',
          logo: cinetwins,
          location: 'Malden',
          link: 'https://www.cinetwins.nl/filmprogramma/loverboy-emoties-uit-16'
        },
        {
          id: 20,
          name: 'Cinema Gold',
          logo: cinemagold,
          location: 'Veldhoven',
          link: 'https://www.cinemagold.nl/filmprogramma/loverboy-emoties-uit-16'
        },
        {
          id: 21,
          name: 'CineLounge',
          logo: cinelounge,
          location: 'Houten',
          link: 'https://www.cinelounge.nl/filmprogramma/loverboy-emoties-uit-16'
        },
        {
          id: 22,
          name: 'TakeTen',
          logo: taketen,
          location: 'Uden',
          link: 'https://www.taketen.nl/filmprogramma/loverboy-emoties-uit-16'
        },
        {
          id: 23,
          name: 'Cineworld',
          logo: cineworld,
            location: 'Beverwijk',
          link: 'https://www.cineworld.nl/films/nu-in-cineworld/loverboy-emoties-uit'
        },
        {
          id: 24,
          name: 'Luxor',
          logo: luxor,
          location: 'Meppel',
          link: 'https://www.luxor.nl/films/loverboy-emoties-uit'
        },
        {
          id: 25,
          name: 'Movie Unlimited',
          logo: kampen,
          location: 'Kampen',
          link: 'https://kampen.movieunlimitedbioscopen.nl/movies/1744/17/loverboy_emoties_uit'
        },{
          id: 26,
          name: 'Hollywoud',
          logo: hollywoud,
          location: 'Almkerk',
          link: 'https://www.hollywoud.nl/movies/1435/17/loverboy_emoties_uit_16'
        },{
          id: 27,
          name: 'De Nieuwe Kolk',
          logo: dnk,
          location: 'Assen',
          link: 'https://www.dnk.nl/agenda-tickets/bioscoop/loverboy-emoties-uit-16-jr/'
        },{
          id: 28,
          name: 'Bios Hardenberg',
          logo: bioshardenberg,
          location: 'Hardenberg',
          link: 'https://www.bioshardenberg.nl/movies/1793/17/loverboy_emoties_uit'
        },{
          id: 29,
          name: 'Cine Sneek',
          logo: cinesneek,
          location: 'Sneek',
          link: 'https://www.cinesneek.nl/movies/2790/17/loverboy_emoties_uit_zonder_pauze'
        },{
          id: 30,
          name: 'Movieskoop',
          logo: movieskoop,
          location: 'Heerenveen, Emmeloord',
          link: 'https://www.movieskoop.nl/movies/2956/17/loverboy_emoties_uit'
        },
        {
          id: 31,
          name: 'Skopein',
          logo: skopein,
          location: 'Winterwijk',
          link: 'https://www.skopein.nl/movies/2451/17/loverboy_emoties_uit'
        },
        {
          id: 32,
          name: 'Pathe België',
          logo: patheLogo,
          location: 'Genk',
          link: 'https://www.pathe.be/nl/films-evenementen/loverboy-emoties-uit-42472'
        }
      ]
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1980px;
}

.cinema-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease;
  height: 100%;
}

.cinema-card h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.cinema-card p {
  margin-bottom: 1rem;
}

.koop-tickets-btn {
  background-color: #d48c37;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
  margin-top: auto;
}

.koop-tickets-btn:hover {
  background-color: #b3742c;
}

@media (max-width: 1200px) {
  .grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media (max-width: 640px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
</style>
